<template>
  <payouts-layout>
    <div class="d-flex justify-content-between align-items-center">
      <filters
      data-testid="filters"
      ref="filters"
      @apply-filters="applyFilters"/>
      <div class="d-flex flex-fill justify-content-end">
        <b-dropdown
          data-testid="export-statement-dropdown"
          class="mt-2 mr-4"
          :disabled="exportLoading">
          <template #button-content>
            <feather type="download"></feather>&nbsp;
          </template>
          <b-dropdown-item data-testid="export-statement-summary" @click="() => this.handleExport('summary')">
            <feather type="download"></feather> {{ translations.statements.btn_export_summaries }}
          </b-dropdown-item>
          <b-dropdown-item data-testid="export-statement-summary-merge" @click="() => this.handleExport('summary_merge')">
            <feather type="download"></feather> {{ translations.statements.btn_export_summary_merge }}
          </b-dropdown-item>
          <b-dropdown-item data-testid="export-statement-details" @click="() => this.handleExport('details')">
            <feather type="download"></feather> {{ translations.statements.btn_export_details }}
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          class="mt-2 text-nowrap"
          :disabled="fetchingCreateStatements"
          @click="createStatements">
            {{ translations.statements.btn_generate }}
        </b-button>
      </div>
    </div>
    <financial-list
    ref="payouts-statements"
    v-model="currentPage"
    :header-data="TABLE_HEADERS"
    :events="TABLE_EVENTS"
    :data="statements"
    :disabled="loading"
    :items-per-page="itemsPerPage"
    :total="total"
    :no-items-message="translations.statements.table.empty_list"
    itemRefKey="id"
    @page-changed="fetchStatements"
    @row-details="() => {}"
    @row-export-details="exportTransactions"
    @row-export-summary="exportSummary"/>
  </payouts-layout>
</template>

<script>
import moment from 'moment';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import PayoutsLayout from '@/components/Commissions/PayoutsLayout.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import translations from '@/translations';
import utils from '@/scripts/tools/utils';
import { formatValue, formatDate, formatDatetime } from '@/helpers/finance';
import Filters from '@/components/Commissions/Statements/filters.vue';

export default {
  name: 'Statements',
  components: {
    PayoutsLayout,
    FinancialList,
    Filters,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.statements.table.id, 'id'),
      associateHeaderDataItem(this.translations.statements.table.rvp_name, 'rvp_name'),
      associateHeaderDataItem(this.translations.statements.table.total_balance, 'total_balance'),
      associateHeaderDataItem(this.translations.statements.table.start_date, 'start_date'),
      associateHeaderDataItem(this.translations.statements.table.end_date, 'end_date'),
      associateHeaderDataItem(this.translations.statements.table.created_at, 'created_at'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.statements.view_details,
        icon: 'eye',
        redirectLink: id => `/commissions/statements/${id}`,
      },
      'row-export-details': {
        title: this.translations.statements.export_details,
        icon: 'download',
      },
      'row-export-summary': {
        title: this.translations.statements.export_summary,
        icon: 'file',
      },
    };
  },
  data() {
    return {
      translations: translations.commissions,
      loading: false,
      fetchingCreateStatements: false,
      statements: [],
      itemsPerPage: 10,
      currentPage: 1,
      exportLoading: false,
      total: 0,
      appliedFilters: {},
    };
  },
  async beforeMount() {
    const page = this.$route.query?.page ? Number(this.$route.query.page) : 1;
    this.currentPage = page;
    this.total = page * this.itemsPerPage;
  },
  mounted() {
    this.restoreFiltersFromQuery();
  },
  methods: {
    restoreFiltersFromQuery() {
      const { query } = this.$route;
      const filters = { rvp_uuid: query?.rvp_uuid || '' };
      const page = query?.page ? Number(query.page) : 1;

      if (query?.start_date_from) {
        filters.start_end_date = {
          start: query.start_date_from,
          end: query.end_date_until || null,
        };
      }

      this.$refs.filters.recoverFiltersFromURL(filters);
      this.applyFilters(filters, page);
    },
    async applyFilters(filters, page = 1) {
      this.currentPage = page;
      this.appliedFilters = { rvp_uuid: filters.rvp_uuid };

      if (filters.start_end_date) {
        this.appliedFilters.start_date_from = filters.start_end_date.start;
        this.appliedFilters.end_date_until = filters.start_end_date.end;
      }

      await this.fetchStatements(this.currentPage);
    },
    async fetchStatements(page) {
      this.loading = true;
      this.currentPage = page;
      this.setParamsToQuery();

      const offset = (page - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;

      try {
        const { statements, total } = await service.getStatements({
          ...this.appliedFilters,
          order_by: 'id',
          order_descending: true,
          offset,
          limit,
        });

        this.statements = statements;
        this.statements.forEach(stmt => {
          stmt.start_date = formatDate(stmt.start_date);
          stmt.end_date = formatDate(stmt.end_date);
          stmt.created_at = formatDatetime(stmt.created_at);
          stmt.total_balance = formatValue(stmt.total_balance);
        });
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.statements.errors.fetch_list}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    async createStatements() {
      try {
        this.fetchingCreateStatements = true;
        await service.createStatements();
        this.currentPage = 1;
        await this.fetchStatements(this.currentPage);
        this.$noty.success(this.translations.statements.success.generate);
      } catch (err) {
        this.$noty.error(`${this.translations.statements.errors.generate}: ${parseResponseError(err)}`);
      } finally {
        this.fetchingCreateStatements = false;
      }
    },
    setParamsToQuery() {
      const q = {
        ...this.appliedFilters,
        page: this.currentPage > 1 ? this.currentPage : undefined,
      };

      const newQuery = new URLSearchParams(q).toString();
      const currentQuery = new URLSearchParams(this.$route.query).toString();

      if (newQuery !== currentQuery) {
        this.$router.replace({
          query: q,
        });
      }
    },
    async exportTransactions(id) {
      try {
        const buf = await service.exportTransactions({ statement_id: id });
        const fileName = `commission_transactions_export_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        utils.downloadFile(buf, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.statements.errors.export_csv}: ${parseResponseError(err)}`);
      }
    },
    async exportSummary(id) {
      try {
        const buf = await service.exportStatementSummary(id);
        const fileName = `commission_statement_${id}_summary_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        utils.downloadFile(buf, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.statements.errors.export_csv}: ${parseResponseError(err)}`);
      }
    },
    async handleExport(type) {
      if (this.exportLoading) return;
      this.exportLoading = true;

      const exportFilters = {
        ...this.appliedFilters,
        type,
      };

      try {
        const { data } = await service.exportStatements(exportFilters);

        const dateFormatted = moment().utc().format('YYYYMMDD_HHmmss');
        let fileName = `commission_details_${dateFormatted}.zip`;
        switch (type) {
          case 'summary':
            fileName = `commission_summaries_${dateFormatted}.zip`;
            break;
          case 'summary_merge':
            fileName = `commission_summary_merge_${dateFormatted}.csv`;
            break;
          default:
            break;
        }

        utils.downloadFile(data, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.statements.errors.export_file}: ${parseResponseError(err)}`);
      } finally {
        this.exportLoading = false;
      }
    },
  },
};
</script>
